import {FormattedMessage} from '@meiko/react-intl';
import PanelHeaderTitle from 'components/generic/ModalHeaderTitle';
import Panel from 'components/generic/Panel';
import React from 'react';
import {Doughnut} from 'react-chartjs-2';

const teleKeys = ['Tele'];
const d2dKeys = ['D2D'];
const marketingKeys = ['Markkinointi', 'Marketing', 'Marknadsföring'];
const salesKeys = ['Myynti IH', 'Sales', 'Försäljning'];

/**
 *
 * A bit hacky solution to get colors by label
 * The keys can be in different languages and we other way to differentiate them than by label
 * @param {string} label
 * @returns {{bg: string, fg: string}}
 */
export const getColorsByLabel = label => {
	if (teleKeys.includes(label)) {
		return {
			bg: 'rgba(255, 99, 132, 1)',
			fg: 'rgba(255, 99, 132, 1)',
		};
	}
	if (d2dKeys.includes(label)) {
		return {
			bg: 'rgba(54, 162, 235, 1)',
			fg: 'rgba(54, 162, 235, 1)',
		};
	}
	if (marketingKeys.includes(label)) {
		return {
			bg: 'rgba(75, 192, 192, 1)',
			fg: 'rgba(75, 192, 192, 1)',
		};
	}
	if (salesKeys.includes(label)) {
		return {
			bg: 'rgba(255, 206, 86, 1)',
			fg: 'rgba(255, 206, 86, 1)',
		};
	}
	return {fg: 'rgba(0, 0, 0, 1)', bg: 'rgba(0, 0, 0, 1)'};
};

export const options = {
	indexAxis: 'y',
	responsive: true,
	plugins: {
		legend: {
			position: 'right',
		},
	},
};

const SalesBySourcesChart = ({data}) => {
	const labels = Object.keys(data ?? []).map(
		key => `${key} ${(data[key] * 100).toFixed(0)} %`,
	);
	const chartData = {
		labels,
		datasets: [
			{
				data: Object.keys(data ?? []).map(key => (data[key] * 100).toFixed(0)),
				backgroundColor: Object.keys(data ?? []).map(key => getColorsByLabel(key).bg),
				borderColor: Object.keys(data ?? []).map(key => getColorsByLabel(key).fg),
				borderWidth: 1,
			},
		],
	};
	return (
		<Panel
			header={
				<PanelHeaderTitle textAlign="center">
					<FormattedMessage id="Sales by source" />
					{' - '}
					<FormattedMessage id="Percentage (%)" />
				</PanelHeaderTitle>
			}
		>
			<Doughnut options={options} data={chartData} />
		</Panel>
	);
};

export default SalesBySourcesChart;
