import React from 'react';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import {pipe, sortBy, toPairs} from 'ramda';
import * as buildingDicts from 'dicts/buildings';
import {compose} from 'redux';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import {selectStyle, components} from './ReactSelect';
import Field from './Field';
import FormBlock from './FormBlock';
import Label from './Label';
import FieldError from './FieldError';
import Select from './Select';

const encounterStateOptions = pipe(
	toPairs,
	sortBy(([_value, title]) => title),
)(buildingDicts.encounterStateNoD2d);

const StyledReactSelect = styled(ReactSelect)`
	${selectStyle};
`;

const MultiValueLabel = props => {
	const {selectProps, data} = props;
	const {value: currentValue} = selectProps;
	const isFirst = currentValue.findIndex(option => option.value === data.value) === 0;

	return isFirst ? (
		<div content={'Customise your multi-value label component!'}>
			<components.MultiValueLabel {...props}>
				+{currentValue.length}
			</components.MultiValueLabel>
		</div>
	) : null;
};

const MultiValueRemove = () => {
	return null;
};

const BuildingEncounterStateSelectFormlet = ({
	intl,
	name = 'encounterState',
	isMulti = false,
	concise = false,
	block = true,
}) => {
	/**
	 * Get the value for the ReactSelect input (isMulti = true).
	 * This is to handle string/array values.
	 *
	 * @param {array|string} value
	 * @returns {array}
	 */
	const getMultiSelectValue = value => {
		if (Array.isArray(value)) {
			return value.map(v => ({
				value: v,
				label: intl.formatMessage({
					id: buildingDicts.encounterStateNoD2d[v],
				}),
			}));
		} else if (value.length) {
			return value.split(',').map(v => ({
				value: v,
				label: intl.formatMessage({
					id: buildingDicts.encounterStateNoD2d[v],
				}),
			}));
		}
		return [];
	};

	const componentProps = concise
		? {
				MultiValueLabel,
				MultiValueRemove,
		  }
		: {};

	return (
		<Field
			name={name}
			component={({input, inputId, meta}) => (
				<FormBlock>
					<Label htmlFor={inputId} style={{marginRight: '10px'}}>
						<FormattedMessage id="Status" />
					</Label>
					{isMulti ? (
						<StyledReactSelect
							minWidth="150px"
							id={inputId}
							classNamePrefix="react-select"
							isValid={true}
							block={block}
							isMulti
							options={encounterStateOptions.map(([val, title]) => ({
								value: val,
								label: intl.formatMessage({id: title}),
							}))}
							components={componentProps}
							value={getMultiSelectValue(input.value)}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							placeholder={intl.formatMessage({id: 'No selection'})}
							noOptionsMessage={() => intl.formatMessage({id: 'No choices'})}
							onChange={value => {
								const newValue = value.length === 0 ? [] : value.map(v => v.value);
								input.onChange(newValue);
							}}
						/>
					) : (
						<Select {...input} id={inputId} block stretch>
							<option value="">{intl.formatMessage({id: 'No selection'})}</option>
							{encounterStateOptions.map(([val, title]) => (
								<option key={val} value={val}>
									{intl.formatMessage({id: title})}
								</option>
							))}
						</Select>
					)}
					<FieldError {...meta} />
				</FormBlock>
			)}
		/>
	);
};

export default compose(injectIntl)(BuildingEncounterStateSelectFormlet);
