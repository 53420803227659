import services from 'services';
import {map, prop, pipe} from 'ramda';
import {describeThrow, describeError} from 'utils/errors';
import {buildingInclude, encounterInclude} from './constants';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getBuilding = buildingId =>
	httpJson('get', `/buildings/${buildingId}`, {include: buildingInclude})
		.catch(e => {
			const noPermission = !!e.response && e.response.status === 403;
			const errMsgKey = noPermission
				? 'You do not have access to the building'
				: 'The building could not be loaded. Try refreshing the page.';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(pipe(prop('data'), normalize.building));

export const getClients = buildingId =>
	httpJson('get', '/clients', {buildingId, include: 'ban'})
		.catch(
			describeThrow(
				intl.formatMessage({
					id: 'The building could not be loaded. Try refreshing the page.',
				}),
			),
		)
		.then(getResponseData(map(normalize.client)));

export const postClient = (client, buildingId) =>
	httpJson('post', '/clients', {}, {body: {buildingId, ...client}}).catch(e => {
		const conflict = !!e.response && e.response.status === 409;
		e.causedByCalendarResourceConflict = conflict;
		return conflict
			? e.response.json().then(body => describeThrow(body.message, e))
			: Promise.reject(describeError(msgs.saveFailed, e));
	});

export const putClient = client =>
	httpJson('put', `/clients/${client.id}`, {}, {body: client}).catch(e => {
		const conflict = !!e.response && e.response.status === 409;
		e.causedByCalendarResourceConflict = conflict;
		return conflict
			? e.response.json().then(body => describeThrow(body.message, e))
			: Promise.reject(describeError(msgs.saveFailed, e));
	});

export const deleteClient = clientId =>
	httpJson('delete', `/clients/${clientId}`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const getEncounter = encounterId =>
	httpJson('get', `/encounters/${encounterId}`, {include: encounterInclude})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.encounter));

export const get16100Clients = buildingId =>
	httpJson('get', `/buildings/${buildingId}/searchClients`)
		.catch(describeThrow(intl.formatMessage({id: 'Could not retrieve 16100 data'})))
		.then(pipe(prop('data'), map(normalize.clients)));

export const getClientData = clientId =>
	httpJson('get', `/buildings/searchClient/${clientId}`)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.clients)));

export const getAreas = () =>
	httpJson('get', '/areas', {
		select: 'id,type,title',
		withoutTransform: true,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getBuildingComments = (buildingId, query = {}) => {
	return httpJson('get', `/buildingComments`, {buildingId, ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));
};

export const postBuildingComment = (buildingId, comment) => {
	return httpJson('post', `/buildingComments`, {buildingId, comment})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));
};
export const updateBuildingComment = (commentId, comment) =>
	httpJson('put', `/buildingComments/${commentId}`, {include: 'user'}, {body: {comment}})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.buildingComment));

export const deleteBuildingComment = (buildingId, commentId) => {
	return httpJson('delete', `/buildingComments/${commentId}`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);
};
