import {
	IMPORT_TYPE_CLIENT,
	STATE_CANCELLED,
	STATE_CLIENTS_DELETED,
	STATE_COMPLETED,
	STATE_DRAFT,
	STATE_DRAFT_PROCESSED,
	STATE_DRAFT_PROCESSING,
	STATE_DRAFT_START_PROCESSING,
	STATE_FAILED,
} from 'modules/buildingsApp/csvImportsPage/constants';
import {state as _encounterState} from './encounters';
import {omit, pick} from 'ramda';

export const type = {
	apartmenthouse: 'Apartment block',
	balconyaccessblock: 'Multi-family residential',
	detached: 'Detached house',
	detachedhousecompany: 'Detached house company',
	other: 'Other',
	otherdetached: 'Other detached House',
	rowhouse: 'Terraced house',
	semidetached: 'Semi-detached house',
	terracedhouse: 'Linked house',
	unknown: 'Unknown',
	vacationhome: 'Leisure home',
};

// NOTE: prefer the alias below.
// these include both encounter states and d2d encounter states, primarily due to legacy reasons
export const encounterState = {
	...omit(['issue'], _encounterState),
	// the ones below are actually d2d encounter states
	notHome: 'Not home',
};

// all encounter states, both regular and d2d. can be convenient
// prefer this over "encounterState". it helps spot usage sites later.
export const encounterStateAll = encounterState;

// provide keys for filtering out d2d encounter states from the "encounterState" dict
export const d2dEncounterStateKeys = ['notHome'];

// try to use these whenever you don't need keys for d2d states, just regular "encounterState"
export const encounterStateNoD2d = omit(d2dEncounterStateKeys, encounterState);

export const d2dEncounterStateOnly = pick(d2dEncounterStateKeys, encounterState);

export const extraState = {
	avoid: 'Just contacted',
	banned: 'Banned',
	noInterest: 'Too new',
};

export const state = {...encounterState, ...extraState};

// TODO change these to translatable
export const heatingType = {
	noFixedHeating: 'Ei kiinteää lämmityslaitetta',
	centralWaterHeating: 'Vesikeskuslämmitys',
	centralAirHeating: 'Ilmakeskuslämmitys',
	airHeating: 'Ilmalämmitys',
	electricHeating: 'Suora sähkölämmitys',
	ovenHeating: 'Uunilämmitys',
	unknown: 'Ei tiedossa',
};

// TODO change these to translatable
export const heatSource = {
	electric: 'Sähkö',
	geothermal: 'Maalämpö',
	lightFuelOil: 'Öljy',
	wood: 'Puu',
	coal: 'Hiili',
	peat: 'Turve',
	gas: 'Kaasu',
	unknown: 'Ei tiedossa',
};

export const csvImportTypes = {
	[IMPORT_TYPE_CLIENT]: 'ImportTypeClient',
};

export const csvImportStates = {
	[STATE_DRAFT]: 'Import: Draft',
	[STATE_DRAFT_START_PROCESSING]: 'Import: Draft start processing',
	[STATE_DRAFT_PROCESSING]: 'Import: Draft processing',
	[STATE_DRAFT_PROCESSED]: 'Import: Draft processed',
	[STATE_FAILED]: 'Import: Failed',
	[STATE_COMPLETED]: 'Import: Completed',
	[STATE_CANCELLED]: 'Import: Cancelled',
	[STATE_CLIENTS_DELETED]: 'Import: Clients deleted',
};

export const csvColumnMappingOptions = {
	skip: 'Column: skip',
	first_name: 'Column: first_name',
	last_name: 'Column: last_name',
	full_name: 'Column: full_name',
	phone: 'Column: phone',
	email: 'Column: email',
	address: 'Column: address',
	zip: 'Column: zip',
	city: 'Column: city',
	robinson: 'Column: robinson',
	building_comment: 'Column: building_comment',
};
