export const workTypes = {
	working: 'Work',
	break: 'Break',
	lunch: 'Lunch',
};

export const oldTypes = {
	sickLeave: 'Sick leave',
	sickLeaveContagion: 'Sick leave, contagion',
	unpaidHoliday: 'Unpaid holiday',
};

export const types = {
	...workTypes,
	...oldTypes,
	sickLeaveWithManagerPermission: "Sick leave with manager's permission",
	sickLeaveMedicalCertificate: 'Sick leave (medifical certificate required)',
	childSick: 'Absence due to child being sick',
	parentalLeave: 'Parental leave',
	annualLeave: 'Annual leave',
	otherUnpaidLeave: 'Other unpaid leave',
	midweekHoliday: 'Bank holiday',
};
