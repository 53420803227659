import React, {lazy, Suspense} from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Switch, Route, Redirect} from 'react-router-dom';
import {applyState} from 'utils/redux';
import {compose} from 'ramda';
import {
	initialized,
	loggedIn,
	user,
	callSession,
	enioCallerVisible,
} from 'modules/common/selectors';
import {rootStyle} from 'styles/fragments';
import {isMobileIos} from 'utils/userAgents';
import {logInfo} from 'io/errors';
import services from 'services';
import * as nActions from 'modules/notifications/actions';
import {longestDur} from 'constants/notifications';
// always include modules that are used everywhere
import Notifications from './Notifications';
import Confirmer from './Confirmer';
import SendSmsModal from './Sms/SendSmsModal';
// always include simple info pages
import NotFoundPage from 'components/generic/NotFoundPage';
import RedirectPage from 'components/views/RedirectPage';
import LoadingPage from 'components/generic/LoadingPage';
import Loading from './Loading';
import NotFound from './NotFound';
import LeaddeskClient from 'views/LeaddeskClient';
import {MenuBackdrop} from 'components/generic/Navbar';

// always include core modules
// TODO: remove
import Launcher from './Launcher';
import Login from './Login';
import EnioCallerClientStable from 'views/EnioCaller/stable/EnioCallerClient';
import EnioCallerClientBeta from 'views/EnioCaller/beta/EnioCallerClient';
import SomicCaller from 'views/SomicCaller';
import {isPilotUser, userUseNewDashboard} from 'utils/perms';
import Dashboard from './Dashboard';
import {SOMIC_CALLER} from 'constants/caller';

const handleLazyViewImportErr = e => {
	// logInfo considers errors without a description unexpected, so provide some
	e.description = '';
	logInfo(e);
	services.get('store').dispatch(
		nActions.warning({
			id: 'view-load-err',
			duration: longestDur,
			message: services
				.get('intl')
				.formatMessage({id: 'Content loading failed. Try refreshing the page'}),
		}),
	);
	// React.lazy expects the imported component to be in the JS Module format, so mimick that with a dummy object
	return {default: Loading};
};

const lazyView = importFunc => lazy(() => importFunc().catch(handleLazyViewImportErr));

const Notices = lazyView(() => import('./Notices'));
const ReportsApp = lazyView(() => import('./ReportsApp'));
const CallsApp = lazyView(() => import('./CallsApp'));
const SalesApp = lazyView(() => import('./SalesApp'));
const UsersApp = lazyView(() => import('./UsersApp'));
const Profile = lazyView(() => import('./Profile'));
const TeamCalendarApp = lazyView(() => import('./TeamCalendarApp'));
const StatsApp = lazyView(() => import('./StatsApp'));
const ProjectSalesApp = lazyView(() => import('./ProjectSalesApp'));
const BuildingsApp = lazyView(() => import('./BuildingsApp'));
const D2DApp = lazyView(() => import('./D2DApp'));
const CalendarApp = lazyView(() => import('./CalendarApp'));
const OverviewApp = lazyView(() => import('./OverviewApp'));
const MapsApp = lazyView(() => import('./MapsApp'));
const LeadsApp = lazyView(() => import('./LeadsApp'));
const SalesmanApp = lazyView(() => import('./SalesmanApp'));
const ReferencesApp = lazyView(() => import('./ReferencesApp'));
const MarketingApp = lazyView(() => import('./MarketingApp'));
const Feedback = lazyView(() => import('./Feedback'));
const ControlCenterApp = lazyView(() => import('./ControlCenterApp'));
const Files = lazyView(() => import('./FilesApp'));

const Wrapper = styled.div`
	${rootStyle};
	${props => {
		if (!props.enioCallerVisible) {
			return '';
		}

		return props?.caller
			? `
			@media all and (max-width: 600px) {
				padding-bottom: 120px;
			}
		`
			: '';
	}}
`;

// needed because of leaddesk talk - its iframe needs to be rendered all the time, otherwise its API script crashes, so we render it at root level. the targeted child element is our actual view - since the container's content flows horizontally, we need to set a max width to our view explicitly so that it doesn't get too wide. currently the max width doesn't take into account whether the leaddesk client is displaying or not, but they fit fine on desktop-sized screens, and leaddesk talk isn't shown in apps that are intended for mobile usage.
const ViewContainer = styled.div`
	overflow: auto;
	display: flex;
	height: 100%;

	> :not(${MenuBackdrop}):not(#ld-sidebar) {
		flex-grow: 1;
		max-width: 100%;
	}
`;

/* eslint-disable react/prop-types */
const MapsApps = props => {
	const {
		match: {path, isExact},
	} = props;
	return (
		<>
			<MapsApp {...props} onBackground={!isExact} />
			{!isExact && (
				<Switch>
					<Route path={`${path}/buildings`} component={BuildingsApp} />
					<Route exact path={`${path}/sales/:id?`} component={SalesApp} />
					<Route component={NotFoundPage} />
				</Switch>
			)}
		</>
	);
};

class App extends React.Component {
	// mobile ios is a special child and requires special treatment
	// cursor: pointer is required for the click event to bubble from elements
	mobileIosProps = isMobileIos() ? {cursor: 'pointer'} : null;
	render() {
		const {initialized, loggedIn, user, enioCallerVisible} = this.props;
		const isPilot = user ? isPilotUser(user) : false;
		const useNewDasbhoard = user ? userUseNewDashboard(user) : false;
		const enioCallerStyles = user?.defaultCall === 'enioCaller' ? true : false;
		const styles = {
			...this.mobileIosProps,
		};

		const IndexComponent = useNewDasbhoard ? Dashboard : Launcher;
		return (
			<Wrapper
				id="app"
				style={styles}
				caller={enioCallerStyles}
				enioCallerVisible={enioCallerVisible}
			>
				<Notifications />
				<Confirmer />
				<SendSmsModal />

				{!initialized ? null : !loggedIn ? (
					<Switch>
						<Route exact path="/auth/callback/azure" component={RedirectPage} />
						<Route component={Login} />
					</Switch>
				) : !user ? (
					<LoadingPage />
				) : (
					<Suspense fallback={<Loading />}>
						<ViewContainer id="view-container">
							<Switch>
								<Redirect exact path="/logout" to="/" />
								<Redirect from="/condo-business/:id" to="/project-sales/condo/:id" />
								<Route exact path="/login" component={LoadingPage} />
								<Route path="/auth/callback/azure/" component={RedirectPage} />
								<Route exact path="/" component={IndexComponent} />
								<Route exact path="/new" component={Dashboard} />
								<Route path="/login-test" component={Launcher} />
								<Route path="/reports" component={ReportsApp} />
								<Route path="/calls" component={CallsApp} />
								<Route path="/users" component={UsersApp} />
								<Route exact path="/sales/:id?" component={SalesApp} />
								<Route path="/notices" component={Notices} />
								<Route path="/profile" component={Profile} />
								<Route path="/team-calendar" component={TeamCalendarApp} />
								<Route path="/calendar" component={CalendarApp} />
								<Route path="/stats" component={StatsApp} />
								<Route path="/project-sales" component={ProjectSalesApp} />
								<Route path="/buildings" component={BuildingsApp} />
								<Route path="/d2d" component={D2DApp} />
								<Route path="/overview" component={OverviewApp} />
								<Route path="/maps" component={MapsApps} />
								<Route path="/leads" component={LeadsApp} />
								<Route path="/salesman-app" component={SalesmanApp} />
								<Route path="/references" component={ReferencesApp} />
								<Route path="/marketing" component={MarketingApp} />
								<Route path="/feedback" component={Feedback} />
								<Route path="/control-center" component={ControlCenterApp} />
								<Route path="/files" component={Files} />
								<Route component={NotFound} />
							</Switch>

							{/* leaddesk API needs this component to be present all the time, so we render it here at the top view. */}
							<LeaddeskClient id="ld-sidebar" />

							{user?.defaultCall === 'enioCaller' && !isPilot ? (
								<EnioCallerClientStable />
							) : null}
							{user?.defaultCall === 'enioCaller' && isPilot ? (
								<EnioCallerClientBeta />
							) : null}
							{user?.defaultCall === SOMIC_CALLER ? <SomicCaller /> : null}
						</ViewContainer>
					</Suspense>
				)}
			</Wrapper>
		);
	}
}

App.propTypes = {
	initialized: propTypes.bool,
	loggedIn: propTypes.bool,
	user: propTypes.object,
};

export default compose(
	withRouter,
	connect(applyState({initialized, loggedIn, user, callSession, enioCallerVisible})),
)(App);
