import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {grayExtraLight, fontWeightMedium, fontSizeM} from 'styles/constants';
import {select} from 'styles/fragments';
import {splitGlobalAttrs} from 'utils/html';

export const StyledSelect = styled.select`
	${select};

	padding-right: 30px; // dropdown icon width + alignment, to keep text from overlapping the icon
`;

export const SelectWrapper = styled.span`
	position: relative;
	display: ${({block}) => (block ? 'block' : 'inline-block')};
	${({stretch}) => (!stretch ? 'width: max-content;' : '')};
`;

export const SelectDropdownIcon = styled.span.attrs({
	className: 'fa fa-angle-down',
})`
	pointer-events: none;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	display: inline-block;
	// setup a big enough height for icon to cover possible overflowing content text
	height: 20px;
	// setup some extra width so that the arrow gets a padding on the left
	width: 15px;
	text-align: right;
	line-height: 20px;
	font-size: ${fontSizeM};
	font-weight: ${fontWeightMedium};
	// background: ${({disabled}) => (disabled ? grayExtraLight : 'white')};
`;

const Select = ({block, stretch, selectProps = {}, meta, ...rest}) => {
	const [wrapperProps, otherSelectProps] = splitGlobalAttrs(rest);
	const isValid = meta ? !((meta.submitFailed || meta.touched) && meta.error) : true;
	return (
		<SelectWrapper block={block} stretch={stretch} {...wrapperProps}>
			<StyledSelect
				block={block}
				stretch={stretch}
				{...otherSelectProps}
				{...selectProps}
				isValid={isValid}
			/>
			<SelectDropdownIcon disabled={selectProps.disabled} />
		</SelectWrapper>
	);
};

Select.propTypes = {
	block: propTypes.bool,
	stretch: propTypes.bool,
	selectProps: propTypes.object,
	meta: propTypes.object,
};

export default Select;
