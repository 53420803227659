import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import * as selectors from './selectors';
import * as io from './io';
import {decorateWithNotifications, notify} from 'io/app';
import services from 'services';
import {shortDur} from 'constants/notifications';
import {getSmsTemplateByKey} from './io';
import {omit} from 'ramda';
import {CUSTOM} from './constants';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const creator = effect(namespace);

export let openSendSmsModal = payload => (getState, dispatch) => {
	const {defaultTemplateKey, availableTemplateKeys} = payload;

	Promise.all(
		availableTemplateKeys.map(k =>
			getSmsTemplateByKey(k).catch(e => {
				notify('error', {
					id: 'missing-sms-template',
					message: intl.formatMessage(
						{id: 'Unable to fetch SMS-template {name}'},
						{name: k},
					),
					duration: shortDur,
				});
			}),
		),
	).then(all => {
		const availableTemplates = all
			.reduce((acc, {data}) => {
				return [...acc, ...data];
			}, [])
			.filter(t => !!t);

		dispatch(
			actions._openSendSmsModal({
				...payload,
				defaultTemplateKey,
				availableTemplates,
			}),
		);
	});
};
openSendSmsModal = creator('openSendSmsModal', openSendSmsModal);

/**
 * Send SMS.
 *
 * @param {Object} payload
 * @param {string} payload.message
 * @param {string[]} payload.receivers
 * @param {string} payload.clientId
 * @param {string} payload.smsTemplateId
 * @param {Object} payload.smsTemplate
 */
export let sendSms = payload => (getState, dispatch) => {
	const cannotSendCustomMessage =
		payload?.smsTemplate?.key === CUSTOM && !selectors.canSendCustomMessage(getState());
	const cannotSendMessage = !selectors.canSendSmsMessage(getState());

	if (cannotSendMessage || cannotSendCustomMessage) {
		notify('error', {
			id: 'no-permission',
			message: intl.formatMessage({id: 'No permission'}),
			duration: shortDur,
		});
		dispatch(actions._sendSms());
		return;
	}

	decorateWithNotifications(
		{
			id: 'send-sms',
			failureStyle: 'error',
			success: intl.formatMessage({id: 'SMS sent'}),
		},
		io.sendSms(omit(['smsTemplate'], payload)),
	)(getState, dispatch)
		.then(() => {
			dispatch(actions._sendSms());
			// Closing resets the state
			dispatch(actions.closeSendSmsModal());
		})
		.catch(e => {
			dispatch(actions._sendSms());
		});
};
sendSms = creator('sendSms', sendSms);
