import {
	formValueSelector,
	change as formChange,
	destroy as reduxFormDestroy,
	reset,
	change,
} from 'redux-form';
import {mergeLeft, isEmpty, prop} from 'ramda';
import {effect} from 'utils/redux';
import {P} from 'utils/types';
import {over} from 'utils/lenses';
import {sortByTitle as sortAreas} from 'utils/areas';
import {getQuery} from 'io/history';
import {decorateWithNotifications} from 'io/app';
import {geocodeGooglePlaceId} from 'io/geo';
import {catchNonFatalDefault, logError, logWarning} from 'io/errors';
import services from 'services';
import {transform} from 'ol/proj';
import msgs from 'dicts/messages';
import {describeThrow} from 'utils/errors';
import {
	getBuildings,
	getBuildingsIds,
	postBuildingsUpdates,
	initAreasMap as doInitAreasMap,
	getAreasStyle,
	getAreasSource,
	findBuildingsFromCsvFile,
	massTagBuildings,
} from './io';
import {csvUniqIdStorageKey, massSelectionLimit} from './constants';
import namespace from './namespace';
import * as actions from './actions';
import * as confirmerActions from 'modules/confirmer/actions';
import * as commonSelectors from 'modules/common/selectors';
import * as selectors from './selectors';
import {
	isContactUpdater,
	parseUrlQuery,
	formatFetchableBuildingsQuery,
	formatMassSelectionQuery,
} from './utils';
import cache from './cache';
import {areaFocusZoom} from 'constants/maps';
import {getAllJsonProperties} from 'utils/maps';
import {getTags} from 'modules/common/io';
import {TYPE_BUILDING} from 'modules/usersApp/tagsPage/constants';
import {CSV_FILE_FORM} from 'views/BuildingsApp/BuildingsPage/components/CsvFileSelector';
import {BUILDINGS_FILTER_FORM} from 'views/BuildingsApp/BuildingsPage/components/BuildingsFilterForm';
import {setJson} from 'io/localStorage';
import {MASS_EDITOR_ENTITY_STATE, MASS_EDITOR_ENTITY_TAGS} from '../constants';
import {createTopic} from 'services/createPusher';

const creator = effect(namespace);

let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const buildingsFilterFormVal = formValueSelector('buildingsFilterForm');

const getActiveAreaIds = (getState, dispatch) => {
	const areas = buildingsFilterFormVal(getState(), 'areas').map(a => a.id);
	return new Set(areas);
};

const selectArea = props => (getState, dispatch) => {
	const areas = buildingsFilterFormVal(getState(), 'areas');
	const newAreas = areas.find(a => a.id === props.id)
		? areas.filter(a => a.id !== props.id)
		: sortAreas([...areas, props]);
	dispatch(formChange('buildingsFilterForm', 'areas', newAreas));

	const selectedAreaIds = getActiveAreaIds(getState, dispatch);
	const {
		areasMap: {areasLayer},
	} = cache.read();
	getAreasStyle({selectedAreaIds})
		.then(x => areasLayer.setStyle(x))
		.catch(logWarning);
};

const setupChannels = (getState, dispatch) => {
	const pusher = services.get('pusher');
	const user = commonSelectors.user(getState());
	const massUpdates = pusher.subscribe(createTopic('massUpdates', user.accountId));

	massUpdates.bind('updated', ({userId, state}) => {
		if (userId !== user.id) {
			return;
		}

		if (state === 'completed') {
			decorateWithNotifications(
				{
					id: 'buildings-updated',
					failureStyle: 'error',
					success: intl.formatMessage({id: 'Mass edit operation completed'}),
				},
				Promise.resolve(),
			)(getState, dispatch)
				.catch(e => {
					throw e;
				})
				.then(() => {
					dispatch(actions.recheckQuery(false));
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		}
	});
};

const clearChannels = (getState, _dispatch) => {
	const user = commonSelectors.user(getState());
	if (!user) {
		// User not available in store (e.g. logged out), disconnect from pusher
		pusher.disconnect();
		return;
	}

	pusher.unsubscribe(createTopic('massUpdates', user.accountId));
};

export let initialize = () => (getState, dispatch) => {
	setupChannels(getState, dispatch);

	const query = parseUrlQuery(getQuery());
	const activeOrgId = commonSelectors.activeOrganizationId(getState());
	const contactUpdater = isContactUpdater(commonSelectors.user(getState()));

	const type =
		(query.types && query.types.includes('apartmenthouse')) || activeOrgId === 5
			? query.onlyWithProjectBuildingId === 'true'
				? 'condo'
				: 'project'
			: 'house';

	// note that we don't request a buildings fetch if we initialized the module without any query parameters in the url. this means that the building list won't load immediately when we first navigate into the module. this reduces load on the server since the default unfiltered list is rarely useful for anybody, and on some teams with many area permission checks loading the full list of buildings is very slow.
	dispatch(
		actions.updateMetaData({query, type, contactUpdater, updateList: !isEmpty(query)}),
	);
};
initialize = creator('initialize', initialize);

export let requestingBuildingsByQuery = () => (getState, dispatch) => {
	const urlQuery = parseUrlQuery(getQuery());
	const buildingsQueryFetchable = formatFetchableBuildingsQuery(urlQuery);

	decorateWithNotifications(
		{id: 'get-buildings', failureStyle: 'error'},
		getBuildings(buildingsQueryFetchable),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(data => {
			dispatch(actions._setBuildings(data));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
requestingBuildingsByQuery = creator(
	'requestingBuildingsByQuery',
	requestingBuildingsByQuery,
);

export let requestingBuildingsIdsByQuery = () => (getState, dispatch) => {
	const pagination = selectors.buildingsPagination(getState());
	const urlQuery = parseUrlQuery(getQuery());
	const buildingsQueryFetchable = formatFetchableBuildingsQuery(urlQuery);
	const selection = formatMassSelectionQuery(buildingsQueryFetchable);

	decorateWithNotifications(
		{
			id: 'get-buildings-ids',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getBuildingsIds(selection),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(data => {
			if (pagination.total > massSelectionLimit) {
				const onConfirmed = () => {
					dispatch(actions._setMassSelection(data));
				};

				dispatch(
					confirmerActions.show({
						message: intl.formatMessage(
							{
								id: 'There are a total of {total} buildings to choose from. You can select up to {maxLimit} buildings at a time. Confirm to select the first {maxLimit} buildings.',
							},
							{total: pagination.total, maxLimit: massSelectionLimit},
						),
						cancelText: intl.formatMessage({id: msgs.cancel}),
						onCancel: () => {
							dispatch(actions._setMassSelection([]));
						},
						onOk: onConfirmed,
					}),
				);
			} else dispatch(actions._setMassSelection(data));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
requestingBuildingsIdsByQuery = creator(
	'requestingBuildingsIdsByQuery',
	requestingBuildingsIdsByQuery,
);

/**
 * Mass updates states or tags for selected buildings
 *
 * @param {Object} data
 * @param {Object} data.payload
 * @param {Object[]} data.ids
 * @param {Object} data.selectionQuery
 * @returns {Void}
 */
export let updatingSelectedBuildings = data => (getState, dispatch) => {
	const {payload, ids, selectionQuery} = data;
	const {entity} = payload;
	let mutations = null;
	let message = null;
	let action = null;

	if (entity === MASS_EDITOR_ENTITY_STATE) {
		mutations = {
			encounterState: payload.state,
			encounterType: 'none',
			encounterDate: new Date(),
		};
		message =
			'You are about to set a new status for {count} buildings. Confirm to continue. It takes a while to process the entire selection.';
		action = postBuildingsUpdates;
	} else if (entity === MASS_EDITOR_ENTITY_TAGS) {
		mutations = {
			tagIds: (payload.tagIds ?? '')
				.trim()
				.split(',')
				.map(id => Number(id)),
		};

		message =
			'You are about to set a new tags for {count} buildings. Confirm to continue. It takes a while to process the entire selection.';
		action = massTagBuildings;
	} else {
		// This should never happen
		logError(new Error(`Unkown entity given to MassUpdate ${entity}`));
		return;
	}

	const requestPayload = {
		ids,
		selectionQuery,
		mutations,
	};
	const onConfirmed = () => {
		decorateWithNotifications(
			{
				id: 'post-buildings-updates',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Started to process mass saving.'}),
			},
			action(requestPayload),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._resetMassEditor());
			});
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage(
				{
					id: message,
				},
				{count: data.ids.length},
			),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirmed,
		}),
	);
};
updatingSelectedBuildings = creator(
	'updatingSelectedBuildings',
	updatingSelectedBuildings,
);

export let destroy = () => (getState, dispatch) => {
	clearChannels(getState, dispatch);
	dispatch(reduxFormDestroy('buildingsFilterForm'));
};
destroy = creator('destroy', destroy);

export let initAreasMap = () => (getState, dispatch) => {
	const apiToken = commonSelectors.apiToken(getState());
	const org = commonSelectors.activeOrganization(getState());
	const selectedAreaIds = getActiveAreaIds(getState, dispatch);
	const onAreaClick = feature =>
		selectArea(getAllJsonProperties(feature))(getState, dispatch);

	decorateWithNotifications(
		{id: 'map-init'},
		doInitAreasMap({
			apiToken,
			organizationId: org.id,
			areasType: 'city',
			selectedAreaIds,
			onAreaClick,
		}),
	)(getState, dispatch)
		.then(resources => {
			cache.update(over(['areasMap'], mergeLeft(resources)));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initAreasMap = creator('initAreasMap', initAreasMap);

export let setAreasMapType = type => (getState, dispatch) => {
	const apiToken = commonSelectors.apiToken(getState());
	const {
		areasMap: {areasLayer},
	} = cache.read();
	getAreasSource({apiToken, areasType: type})
		.then(x => areasLayer.setSource(x))
		.catch(catchNonFatalDefault(getState, dispatch));
};
setAreasMapType = creator('setAreasMapType', setAreasMapType, P.String);

export let openAreasSelectorSuggestion = placeId => (getState, dispatch) => {
	geocodeGooglePlaceId(placeId)
		.then(res => {
			const {lat, lng} = res.geometry.location;
			const coord = transform([lng(), lat()], 'EPSG:4326', 'EPSG:3857');
			const {
				areasMap: {map},
			} = cache.read();
			const view = map.getView();
			view.animate({center: coord, zoom: areaFocusZoom});
		})
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.catch(catchNonFatalDefault(getState, dispatch));
};
openAreasSelectorSuggestion = creator(
	'openAreasSelectorSuggestion',
	openAreasSelectorSuggestion,
	P.String,
);

export let getAvailableTags = () => (getState, dispatch) => {
	getTags({getAllTags: true, type: TYPE_BUILDING}).then(({data: tags}) => {
		dispatch(actions._getAvailableTags(tags));
	});
};
getAvailableTags = creator('getAvailableTags', getAvailableTags);

/**
 * Handles the upload of a CSV file (used to find buildings by uniqId)
 *
 * @param {Object} payload
 * @param {File} payload.file - CSV file
 */
export let handleCsvFileSelectorUpload = payload => (getState, dispatch) => {
	findBuildingsFromCsvFile(payload)
		.then(data => {
			const uniqId = prop('uniqId', data);
			if (uniqId) {
				const {expiresAt} = data;
				// Add the id to the filter form
				dispatch(change(BUILDINGS_FILTER_FORM, 'uniqId', uniqId));
				// Store the expiration in localStorage so we can filter out old files
				setJson(csvUniqIdStorageKey, {expiresAt});
				dispatch(actions.closeCsvFileSelector());
			}
		})
		.finally(() => {
			dispatch(actions._csvFileSelectorUpload({}));
			dispatch(reset(CSV_FILE_FORM));
		})
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.catch(catchNonFatalDefault(getState, dispatch));
};
handleCsvFileSelectorUpload = creator(
	'handleCsvFileSelectorUpload',
	handleCsvFileSelectorUpload,
);
