import styled from 'styled-components';
import {FormattedMessage, FormattedNumber} from '@meiko/react-intl';
import PanelHeaderTitle from 'components/generic/ModalHeaderTitle';
import Panel from 'components/generic/Panel';
import Table from 'components/generic/Table';
import {compose, sortBy} from 'ramda';
import {currency, currencySymbol} from 'constants/loc';
import React from 'react';

const TrophyContainer = styled.span`
	width: 20px;
	display: inline-block;
	color: gold;
	i {
		text-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.8),
			0 0 15px rgba(255, 215, 0, 0.9); /* Glowing effect */
	}
`;

const TopList = ({data = []}) => {
	const toplist = sortBy(
		compose(a => {
			return -Number(a.sum);
		}),
		data,
	);
	return (
		<Panel
			header={
				<PanelHeaderTitle textAlign="center">
					<FormattedMessage id="Top list" />
				</PanelHeaderTitle>
			}
		>
			<Table>
				<thead>
					<tr>
						<th></th>
						<th>
							<TrophyContainer />
							<FormattedMessage id="Name" />
						</th>
						<th>{currencySymbol}</th>
					</tr>
				</thead>
				<tbody>
					{toplist.map((row, i) => (
						<tr key={i}>
							<td>{i + 1}</td>
							<td>
								<TrophyContainer>
									{i === 0 && <i className="fa fa-trophy" />}
								</TrophyContainer>
								{row.user}
							</td>
							<td>
								{/* eslint-disable-next-line */}
								<FormattedNumber value={row.sum} style="currency" currency={currency} />
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Panel>
	);
};
export default TopList;
