import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import * as normalize from 'utils/normalize';
import {mapResponseData} from 'utils/app';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));

/**
* Send SMS.
* @param {string} body
* @returns {Promise}

*/
export const sendSms = body =>
	httpJson('post', '/sendSms', {}, {body}).catch(
		describeThrow(intl.formatMessage({id: 'SMS sending failed'})),
	);

/**
 * Get sms template by key.
 * @param {string} key
 * @returns {Promise}
 */
export const getSmsTemplateByKey = key =>
	httpJson('GET', `/smsTemplates/allByKey/${key}`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(normalize.smsTemplate));
