import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as commonSelectors from 'modules/common/selectors';
import * as selectors from './selectors';
import * as actions from './actions';
import {
	postRestoreLead,
	getProducts,
	getOrganizations,
	getHandlers,
	putLead,
	getAreas,
} from './io';
import {deleteLead, getLeads, getSources, getUtmOptions} from '../common/io';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {mergeLeft, equals} from 'ramda';
import {parseUrlQuery} from './utils';
import services from 'services';
import msgs from 'dicts/messages';
import {leadsQueryBase} from './constants';
import {createTopic} from 'services/createPusher';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));

const setupChannels = (getState, dispatch) => {
	const user = commonSelectors.user(getState());

	const pusher = services.get('pusher');
	const leads = pusher.subscribe(createTopic('lead', user.accountId));

	leads.bind('handlerSet', payload => {
		dispatch(actions._leadHandlerSet(payload));
	});
};

const clearChannels = (getState, _dispatch) => {
	const user = commonSelectors.user(getState());
	if (!user) {
		// User not available in store (e.g. logged out), disconnect from pusher
		pusher.disconnect();
		return;
	}
	pusher.unsubscribe(createTopic('lead', user.accountId));
};

const fetchLeads =
	({notifyOpts = {}}) =>
	(getState, dispatch) => {
		return decorateWithNotifications(
			{id: 'get-leads', failureStyle: 'warning', ...notifyOpts},
			getLeads({...selectors.leadsQueryFetchable(getState()), ...leadsQueryBase}),
		)(getState, dispatch).then(leads => {
			dispatch(actions._setLeads(leads));
		});
	};

export let initialize = () => (getState, dispatch) => {
	setupChannels(getState, dispatch);

	const {leadsQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateLeadsQuery(leadsQuery));

	decorateWithNotifications(
		{id: 'get-settings', failureStyle: 'warning'},
		Promise.all([
			getProducts().then(data => {
				dispatch(actions._setProducts(data.data));
			}),
			getOrganizations().then(data => {
				dispatch(actions._setOrganizations(data.data));
			}),
			getSources().then(sources => dispatch(actions._setSources(sources))),
			getUtmOptions().then(c => dispatch(actions._setUtmOptions(c))),
			getHandlers().then(handlers => dispatch(actions._setHandlers(handlers))),
			getAreas().then(areas => dispatch(actions._setAreas(areas))),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));

	fetchLeads({})(getState, dispatch)
		.then(_ => dispatch(actions._initialize()))
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateLeads = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchLeads({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateLeads = creator('updateLeads', updateLeads);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const leadsQuery = selectors.leadsQuery(getState());
	if (!equals(leadsQuery, urlQueries.leadsQuery)) {
		dispatch(actions._updateLeadsQuery(urlQueries.leadsQuery));
		fetchLeads({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let removeLead =
	({lead, deletedReason, otherReason}) =>
	(getState, dispatch) => {
		const reason = otherReason ? otherReason : deletedReason;
		const onConfirmed = () => {
			dispatch(actions._setProcessing(true));
			decorateWithNotifications(
				{
					id: 'delete-lead',
					failureStyle: 'error',
					loading: intl.formatMessage({id: msgs.processing}),
					success: intl.formatMessage({id: 'Lead removed'}),
				},
				deleteLead({id: lead.id, deletedReason: reason}),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(() => {
					dispatch(actions._removedLead());
					const {_page: page} = selectors.leadsQuery(getState());
					if (selectors.leads(getState()).length <= 1 && page > 1) {
						dispatch(actions._updateLeadsQuery({_page: page - 1}));
						pushQuery(mergeLeft(selectors.urlQuery(getState())));
					}

					return fetchLeads({
						notifyOpts: {loading: intl.formatMessage({id: msgs.loading})},
					})(getState, dispatch);
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		};

		onConfirmed();
	};

export let restoreLead = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'restore-lead',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Lead restored'}),
		},
		postRestoreLead(id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => {
			const {_page: page} = selectors.leadsQuery(getState());
			if (selectors.leads(getState()).length <= 1 && page > 1) {
				dispatch(actions._updateLeadsQuery({_page: page - 1}));
				pushQuery(mergeLeft(selectors.urlQuery(getState())));
			}

			return fetchLeads({
				notifyOpts: {loading: intl.formatMessage({id: msgs.loading})},
			})(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let getFilteredLeads = () => (getState, dispatch) => {
	fetchLeads({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

export let updateLead = lead => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'put-lead',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: msgs.saved}),
		},
		putLead(lead),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setProcessing(false));
			throw e;
		})
		.then(l => {
			dispatch(actions._leadUpdated(l));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateLead = creator('updateLead', updateLead);

export let destroy = () => (getState, dispatch) => {
	clearChannels(getState, dispatch);
};
destroy = creator('destroy', destroy);
