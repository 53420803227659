import React from 'react';
import styled from 'styled-components';
import {
	screenSmMax,
	borderColorBase,
	fontSizeS,
	textColorLight,
	fontSizeM,
	screenMdMin,
} from 'styles/constants';

const StyledSideDrawer = styled.div`
	position: absolute;
	z-index: 101;
	top: ${props => props.top}; /* from header */
	bottom: 0;
	right: 0;
	overflow: auto;
	display: ${props => props.display};
	opacity: ${props => (props.isOpen ? 1 : 0)};
	transition: opacity 0.3s linear;
	transform: translateX(100%);
	animation: ${props =>
		props.isOpen ? 'slideIn 0.3s forwards' : 'slideOut 0.3s forwards'};

	@media screen and (min-width: ${screenMdMin}) {
		${({isOpen, openedWidth}) => (isOpen ? `width: ${openedWidth};` : 'width: 0;')}
		transition: width 0.3s;
	}
	background: #fff;
	width: 100%;

	@media (max-width: ${screenSmMax}) {
		left: 0;
		overflow: auto;
	}

	@keyframes slideIn {
		100% {
			transform: translateX(0%);
		}
	}

	@keyframes slideOut {
		0% {
			transform: translateX(0%);
		}
		100% {
			transform: translateX(100%);
		}
	}
`;

const Backdrop = styled.div`
	visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
	opacity: ${props => (props.isOpen ? 1 : 0)};
	pointer-events: ${props => (props.isOpen ? 'auto' : 'none')};
	z-index: ${props => (props.isOpen ? 100 : -1)};
	background: rgba(0, 0, 0, 0.25);
	transition: opacity 350ms ease, visibility 350ms ease;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
`;

const SideDrawer = ({children, isOpen, onClose, top = '0', openedWidth = '500px'}) => {
	const [display, setDisplay] = React.useState(isOpen ? 'block' : 'none');
	const timerRef = React.useRef(null);

	/**
	 * Use effect to handle the display of the side drawer.
	 * When the side drawer is open, it will set the display to block.
	 * When the side drawer is closed, it will set the display to none after the animation is done.
	 * This prevents the side drawer from being visible (overflown) when it is closed.
	 */
	React.useEffect(() => {
		if (!isOpen) {
			clearTimeout(timerRef.current);
			timerRef.current = setTimeout(() => {
				setDisplay('none');
			}, 350);
		} else {
			setDisplay('block');
		}

		return () => {
			clearTimeout(timerRef.current);
		};
	}, [isOpen]);
	return (
		<>
			<StyledSideDrawer
				top={top}
				isOpen={isOpen}
				openedWidth={openedWidth}
				display={display}
			>
				{children}
			</StyledSideDrawer>
			<Backdrop isOpen={isOpen} onClick={onClose} />
		</>
	);
};
export default SideDrawer;

export const SideDrawerOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: ${props => (props.isOpen ? 'rgba(0, 0, 0, 0.5)' : 'none')};
	z-index: ${props => (props.isOpen ? 1 : -1)};
	opacity: ${props => (props.isOpen ? 0 : 1)};
	transition: opacity 0.3s linear;
`;

export const SideDrawerContent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 15px;
`;

export const SideDrawerHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${borderColorBase};
	background: #f9f9fb;
	width: 100%;
	font-size: ${fontSizeM};
	padding-left: 15px;
	height: 41px;
	overflow: hidden;
`;

export const SideDrawerSubHeader = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${borderColorBase};
	height: 40px;
	padding: 0 15px;
	font-size: ${fontSizeS};
	color: ${textColorLight};
`;
