import {effect} from 'utils/redux';
import {decorateWithNotifications} from 'io/app';
import * as rootSelectors from 'modules/common/selectors';
import * as confirmerActions from 'modules/confirmer/actions';
import * as nActions from 'modules/notifications/actions';
import {medDur} from 'constants/notifications';
import services from 'services';
import {catchNonFatalDefault} from 'io/errors';

import namespace from './namespace';
import {
	fetchCsvImport as fetchCsvImportIo,
	updateCsvImport,
	removeCsvImport as removeCsvImportIo,
	deleteImportedClients as deleteImportedClientsIo,
} from './io';
import {
	_initialize,
	_startProcessing,
	_removeCsvImport,
	_cancelCsvImport,
	_deleteImportedClients,
} from './actions';
import {csvImport as csvImportSelector} from './selectors';
import msgs from 'dicts/messages';
import {
	STATE_COMPLETED,
	STATE_DRAFT_PROCESSED,
	STATE_DRAFT_START_PROCESSING,
	STATE_FAILED,
	STATE_CANCELLED,
} from '../csvImportsPage/constants';
import {createTopic} from 'services/createPusher';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));

const history = services.get('history');

const setupChannels = (getState, dispatch) => {
	const user = rootSelectors.user(getState());
	const reports = pusher.subscribe(createTopic('imports', user.accountId));

	reports.bind('updated', async updatedCsvImport => {
		const currentCsvImport = csvImportSelector(getState());

		if (!updatedCsvImport || updatedCsvImport.id !== currentCsvImport?.id) {
			return;
		}

		const csvImport = await fetchCsvImportIo(updatedCsvImport.id);
		dispatch(_initialize([csvImport]));

		if (csvImport.state === STATE_DRAFT_PROCESSED) {
			dispatch(
				nActions.success({
					id: 'csv-import-updated',
					message: intl.formatMessage({id: 'Import: Draft processed'}),
					duration: medDur,
				}),
			);
		} else if (csvImport.state === STATE_COMPLETED) {
			dispatch(
				nActions.success({
					id: 'csv-import-updated',
					message: intl.formatMessage({id: 'Import: Completed'}),
					duration: medDur,
				}),
			);
		} else if (csvImport.state === STATE_FAILED) {
			dispatch(
				nActions.error({
					id: 'csv-import-updated',
					message: intl.formatMessage({id: 'Import: Failed'}),
					duration: medDur,
				}),
			);
		}
	});
};

const clearChannels = (getState, _dispatch) => {
	const user = rootSelectors.user(getState());
	if (!user) {
		// User not available in store (e.g. logged out), disconnect from pusher
		pusher.disconnect();
		return;
	}
	pusher.unsubscribe(createTopic('imports', user.accountId));
};

export let initialize = id => async (getState, dispatch) => {
	setupChannels(getState, dispatch);
	const csvImport = await fetchCsvImportIo(id);
	dispatch(_initialize([csvImport]));
};
initialize = creator('initialize', initialize);

export let destroy = () => (getState, dispatch) => {
	clearChannels(getState, dispatch);
};
destroy = creator('destroy', destroy);

export let startProcessing =
	({id, headers}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'start-processing-csv-import',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: msgs.saved}),
			},
			updateCsvImport(id, {
				headers,
				state: STATE_DRAFT_START_PROCESSING,
			}),
		)(getState, dispatch)
			.then(csvImport => dispatch(_startProcessing([csvImport])))
			.catch(catchNonFatalDefault);
	};
startProcessing = creator('startProcessing', startProcessing);

export let removeCsvImport = id => async (getState, dispatch) => {
	const onConfirmed = () => {
		decorateWithNotifications({}, removeCsvImportIo(id))(getState, dispatch)
			.then(() => {
				dispatch(_removeCsvImport());
				history.push(`/buildings/csv-imports`);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({
				id: msgs.remove,
			}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirmed,
		}),
	);
};
removeCsvImport = creator('removeCsvImport', removeCsvImport);

export let cancelCsvImport = id => (getState, dispatch) => {
	const onConfirmed = () => {
		decorateWithNotifications(
			{},
			updateCsvImport(id, {
				state: STATE_CANCELLED,
			}),
		)(getState, dispatch)
			.then(data => {
				dispatch(_cancelCsvImport(data));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({
				id: 'Cancel import',
			}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirmed,
		}),
	);
};

export let deleteImportedClients = id => (getState, dispatch) => {
	const onConfirmed = () => {
		decorateWithNotifications(
			{
				id: 'delete-imported-clients',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
			},
			deleteImportedClientsIo(id),
		)(getState, dispatch)
			.then(data => {
				dispatch(_deleteImportedClients(data));
			})
			.catch(catchNonFatalDefault);
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({
				id: 'Confirm the action',
			}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirmed,
		}),
	);
};
