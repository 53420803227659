import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import PopperOverlay from 'components/generic/PopperOverlay';
import {popperOverlayAnimated, popup} from 'styles/fragments';
import TimeTrackerButtons, {TimerButton} from 'components/views/TimeTrackerButtons';
import useWindowWidth from 'hooks/windowWidth';
import {screenSmMinValue} from 'styles/constants';

const Root = styled.span``;

const TableOverlay = styled.div`
	${popperOverlayAnimated};
	${popup};
	padding: 0 20px;
	/* this is needed to undo possible margin from Navbar's "NavActions" */
	margin: 0;
	max-width: 400px;
	min-width: 200px;
`;

const TimeTracker = ({
	userEntries,
	activeTimerRunning,
	startTimer,
	stopTimer,
	processingTimeEntries,
}) => {
	const [hideText, setHideText] = React.useState(false);
	const {width} = useWindowWidth();
	React.useEffect(() => {
		setHideText(width < screenSmMinValue);
	}, [width]);
	return (
		<PopperOverlay
			triggers={['click']}
			rootClass={Root}
			rootContent={
				<TimerButton
					activeTimerRunning={activeTimerRunning}
					processingTimeEntries={processingTimeEntries}
					userEntries={userEntries}
					startTimer={startTimer}
					buttonType={activeTimerRunning === 'working' ? 'total' : activeTimerRunning}
					headerButton={true}
					hideText={hideText}
				/>
			}
			popperProps={{
				placement: 'bottom',
				modifiers: [{name: 'offset', options: {offset: [0, 5]}}],
			}}
			overlayClass={TableOverlay}
			overlayContent={
				<TimeTrackerButtons
					userEntries={userEntries}
					activeTimerRunning={activeTimerRunning}
					startTimer={startTimer}
					stopTimer={stopTimer}
					processingTimeEntries={processingTimeEntries}
				/>
			}
		/>
	);
};

TimeTracker.propTypes = {
	userEntries: propTypes.object,
	activeTimerRunning: propTypes.string,
	startTimer: propTypes.func,
	stopTimer: propTypes.func,
	processingTimeEntries: propTypes.bool,
};

export default TimeTracker;
